import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import pageContentQuery from './components/PageContentQuery.gql';
import NavigationCategoriesQuery from './components/Layout/Header/NavigationCategories.gql';

import React from 'react';
import { ReactComponent as Svenskahem } from './svg/Logotype.svg';
import { ReactComponent as Umea } from './svg/SH_UMEA.svg';
import { ReactComponent as Wikamobler } from './svg/Wika.svg';
import { ReactComponent as Strangnas } from './svg/Bernhardssons.svg';
import { ReactComponent as Stegbo } from './svg/Stegbo.svg';
import { ReactComponent as Asells } from './svg/Asells.svg';
import { theme } from './components/Theme';
import { addwishTracker } from '@jetshop/flight-addwish';

const config = {
  theme,
  apolloConfig: {
    useGraphQLProxyServer: true,
    shopid: process.env.REACT_APP_SHOP_ID || 'svenskahem',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    persistedQueries: [
      {
        query: pageContentQuery,
        variables: { id: Number(process.env.REACT_APP_CONFIG_PAGE) || 100 }
      },
      // megamenu query
      {
        query: NavigationCategoriesQuery,
        variables: { id: Number(process.env.REACT_APP_HEADER_PAGE) || 99 }
      }
    ],
    channelsQuery
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {}
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  trackers: [addwishTracker('A421225CAC55C244B5D8867440C45B82')],
  googleMapsApiKey:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    'AIzaSyCVGBh6O8n3SGwX0wEnPJO4bVQrFURPi90', // NOTE: old fallback key
  useNewFilterAPI: true,
  routes: {
    brands: {
      id: Number(process.env.REACT_APP_BRAND_CATEGORY) || 141,
      path: '/varumarken'
    },
    offers: {
      path: '/erbjudanden'
    },
    favourites: {
      path: '/favoriter'
    },
    search: {
      path: '/sok'
    },
    myPages: {
      path: '/mina-sidor'
    },
    signup: {
      path: '/skapa-konto'
    },
    login: {
      path: '/logga-in'
    },
    stores: {
      path: '/butiker'
    },
    inspiration: {
      path: '/inspiration'
    },
    forgotPassword: {
      path: '/glomt-losenord'
    },
    resetPassword: {
      path: '/aterstall-losenord'
    },
    integrityPage: {
      path: '/integritetspolicy'
    },
    customerService: {
      path: '/kontakta-oss'
    }
  },
  siteConfig: {
    downForMaintenance: false,
    configPageId: Number(process.env.REACT_APP_CONFIG_PAGE) || 100,
    headerPageId: Number(process.env.REACT_APP_HEADER_PAGE) || 99,
    footerPageId: Number(process.env.REACT_APP_FOOTER_PAGE) || 52,
    brandsPageId: Number(process.env.REACT_APP_BRANDS_PAGE) || 113,
    inspirationCategoryId: Number(process.env.REACT_APP_INSP_CATEGORY) || 576,
    designerCategoryId: Number(process.env.REACT_APP_DESIGNER_CATEGORY) || 1159,
    productsCategoryId: Number(process.env.REACT_APP_PRODUCTS_CATEGORY) || 1158,
    socialmedia:
      process.env.REACT_APP_SOCIALMEDIA ||
      '[{ "Facebook": "https://www.facebook.com/svenskahem.officiell" }, { "Instagram": "https://www.instagram.com/svenskahem_officiell" }, { "LinkedIn": "https://linkedin.com/company/svenskahem" }, { "Pinterest": "https://www.pinterest.se/svenskahem" }]'
  },
  logos: {
    'svenskahem-test': <Svenskahem className="svenskahem" />,
    svenskahem: <Svenskahem className="svenskahem" />,
    'sh-umea': <Umea className="umea" />,
    'sh-wikamobler': <Wikamobler className="wikamobler" />,
    'sh-strangnas': <Strangnas className="strangnas" />,
    'sh-stegbomobler': <Stegbo className="stegbo" />,
    'sh-asells': <Asells className="asells" />
  }
};

export default config;
